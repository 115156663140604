import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { DEFAULT_BLUE } from "../../../styles/common"

const Wrapper = styled.div`
display: flex;
background-color: ${DEFAULT_BLUE};
padding-left: 13vw;
padding-right: 13vw;
`
const TeasersWrapper = styled.div`
display: flex;
background-color: ${DEFAULT_BLUE};
overflow: scroll;
`
const Slider = styled.div`
display: flex;
transition: width 1s ease-in;
`
const TeaserWrapper = styled.div`
margin: 1rem;
opacity: 0.4;
transition: all 0.3s ease-in;
&:hover {
  cursor: pointer;
  opacity: 1;
}
`

const marginOffset = 17
const teaserWidth = 15

const Teaser = ({ teasers, selected, handleClick }) => {
  const [margin, setMargin] = useState(0)
  const [numberOfVisible, setNumberOfVisible] = useState(0)
  const [lastSelected, setLastSelected] = useState(0)
  const wrapperRef = useRef(null)

  const slide = () => {
    const visibleSpan = wrapperRef.current.offsetWidth
    const currentVisibleMin = Math.round(-margin / marginOffset)
    const currentVisibleMax = Math.round(
      visibleSpan / marginOffset / 10 - margin / marginOffset
    )
    if (selected <= currentVisibleMin) {
      setMargin(margin + (currentVisibleMin - selected) * marginOffset)
    }
    if (selected >= currentVisibleMax) {
      setMargin(margin - (selected - currentVisibleMax + 1) * marginOffset)
    }
    setNumberOfVisible(visibleSpan / marginOffset / 10)
  }


  const handleResize = () => {
      if (wrapperRef) {
        const visibleSpan = wrapperRef.current.offsetWidth
        setNumberOfVisible(visibleSpan / marginOffset / 10)
      }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return function cleanupListener() {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (lastSelected != selected) {
      slide()
      setLastSelected(selected)
    }
  }, [selected, wrapperRef])

  const increaseMargin = () => {
    setMargin(margin + marginOffset)
  }
  const decreaseMargin = () => {
    setMargin(margin - marginOffset)
  }

  const getStyle = index => {
    let style = {}
    if (index === 0) {
      style = { ...FirstStyle }
    }
    if (index === selected) {
      style = { ...SelectedStyle }
    }
    return style
  }
  const FirstStyle = {
    width: "15rem",
    height: "15rem",
    margin: "1rem",
    borderRadius: "10px",
    marginLeft: margin * 10 + "px",
  }
  const SelectedStyle = {
    width: `${teaserWidth}rem`,
    height: `${teaserWidth}rem`,
    margin: "1rem",
    borderRadius: "10px",
    opacity: "1",
  }

  const TeaserStyle = {
    width: `${teaserWidth}rem`,
    height: `${teaserWidth}rem`,
  }

  return (
    <Wrapper>
      <TeasersWrapper ref={wrapperRef}>
        <Slider>
          {teasers.map((image, index) => (
            <TeaserWrapper
              key={index}
              onClick={() => handleClick(index)}
              style={getStyle(index)}
            >
              <Img fluid={image.fluid} style={TeaserStyle} />
            </TeaserWrapper>
          ))}
        </Slider>
      </TeasersWrapper>
    </Wrapper>
  )
}

export default Teaser
