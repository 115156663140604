import React from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import CarouselContainer from "../components/common/carousel"

export const query = graphql`
  {
    prismic {
      allGallerys {
        edges {
          node {
            images {
              image_information
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const Galleri = ({ data }) => {
  const gallery = data.prismic.allGallerys.edges[0].node

  const images = gallery.images.map(image => {
    return {
      information: image.image_information,
      fluid: image.imageSharp.childImageSharp.fluid,
    }
  })

  return (
    <Layout currentSiteUrl='/galleri' hideFooter>
      <SEO title='Galleri' />
      <CarouselContainer images={images} teasers={images} />
    </Layout>
  )
}
export default Galleri
