import React, { useState } from "react"
import Img from "gatsby-image"
import Arrow from "./arrow"
import styled from "styled-components"
import Teaser from "./teaser"
import { useSwipeable } from "react-swipeable"
import { RichText } from "prismic-reactjs"

const Wrapper = styled.div`
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
`
const InnerWrapper = styled.div`
  width: 100vw;
  height: 70vh;
  & > p {
    text-align: center;
    font-size: 21px;
    letter-spacing: -0.003em;
    line-height: 32px;
    margin: 1rem;
  }
`

const CarouselContainer = ({ images, teasers }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const nextImage = () => {
    setSelectedIndex(selectedIndex < images.length - 1 ? selectedIndex + 1 : 0)
  }
  const prevImage = () => {
    setSelectedIndex(
      selectedIndex === 0 ? images.length - 1 : selectedIndex - 1
    )
  }

  const imgWrapperStyle = { height: "55vh", margin: "3rem 13vw 3rem 13vw" }
  const imgStyle = { objectFit: "contain", pointerEvents: "none" }

  const handlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => prevImage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <div className='background'>
      <div style={{ height: "10rem", width: "100vw" }}></div>
      <Wrapper {...handlers}>
        <InnerWrapper>
          <Img
            fluid={images[selectedIndex].fluid}
            style={imgWrapperStyle}
            imgStyle={imgStyle}
          />
          {images[selectedIndex].information && (
            <p><i>{RichText.asText(images[selectedIndex].information)}</i></p>
          )}
        </InnerWrapper>
        <Arrow invert handleClick={prevImage} />
        <Arrow handleClick={nextImage} />
      </Wrapper>
      <Teaser
        teasers={teasers}
        selected={selectedIndex}
        handleClick={setSelectedIndex}
      />
    </div>
  )
}

export default CarouselContainer
