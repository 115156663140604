import React from "react"
import { IconButton, Icon } from "@material-ui/core"
import { DEFAULT_BLUE } from "../../../styles/common"

const Arrow = ({ invert, handleClick }) => (
  <IconButton
    onClick={() => handleClick()}
    style={{ position: "absolute", alignSelf: "center", zIndex: '100', right: invert ? '' : '3vw', left: invert ? '3vw' : '' }}
  >
    <Icon fontSize="large" style={{color: DEFAULT_BLUE}}>{invert ? "arrow_back" : "arrow_forward"}</Icon>
  </IconButton>
)

export default Arrow
